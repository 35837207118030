import React, { Component } from 'react';
import './App.css';
import './App.css'
import logo from './assets/logo.png'
import app from './assets/app.png'
import arrow from './assets/double-arrow.png'
import logoWhite from './assets/logo-white.png'
import phone1 from './assets/phone1.png'
import phone2 from './assets/phone2.png'
import phone3 from './assets/phone3.png'
import func1 from './assets/func1.png'
import func2 from './assets/func2.png'
import func3 from './assets/func3.png'
import coffee from './assets/coffee.jpg'
import location from './assets/location.png'
import phone from './assets/phone.png'
import email from './assets/email.png'

class APP extends Component {
  constructor(props) {
    super(props)
    this.state = {  }
  }
  render() { 
    return (
      <div id="App">
        <header>
          <div className="fixed-width header-wrapper">
            <img src={logo} alt="" className="logo"></img>
            <div className="anchor">
              <a href="#home" className="anchor-item">首页</a>
              <a href="#service" className="anchor-item">功能</a>
              <a href="#about" className="anchor-item">关于我们</a>
              <a href="#why" className="anchor-item">特色</a>
              <a href="#contact" className="anchor-item">联系我们</a>
            </div>
          </div>
        </header>
        <section id="home">
          <div className="fixed-width home-wrapper">  
            <div className="home-left">
              <div className="light">一款原创用户提供强大的</div>
              <div className="bold">文本及图片编辑功能APP</div>
              <div className="stxt">渔鼓是一款图、文、视频、二次创作转发</div>
              <div className="stxt2">可分享应用的APP、产品覆盖web及移动各端</div>
              <div className="img-div">
                <img src={arrow} className="arrow" alt=""/>
              </div>
              <div className="img-div">
                <img src={logoWhite} className="logo2" alt=""/>
              </div>
            </div>
            <img src={phone1} className="phone1" alt=""/>
          </div>
        </section>
        <section id="service">
          <div className="service-title">
            <span className="black">主要</span>
            <span className="red">功能</span>
          </div>
          <div className="outest-flex">
            <div className="service-content fixed-width">
              <div className="flex-col">
                <div className="icon-block">
                  <img src={func1} className="icon" alt=""/>
                </div>
                <div className="col-title">热点推送</div>
                <div className="col-desc">
                  推送页含：热门/美文/社会/体育/艺术/生活/等，关注页相当于朋友圈列表，以时间更新顺序由上往排列，上下滑动查看。
                </div>
              </div>
              <div className="flex-col">
                <div className="icon-block">
                  <img src={func2} className="icon" alt=""/>
                </div>
                <div className="col-title">分享创作</div>
                <div className="col-desc">
                  可二次创作，打破微信公众号、百度文章等不能直接转发至其他平台的桎梏，解决微博、美篇不能转发其他平台文章的痛点。
                </div>
              </div>
              <div className="flex-col">
                <div className="icon-block">
                  <img src={func3} className="icon" alt=""/>
                </div>
                <div className="col-title">陶冶情操</div>
                <div className="col-desc">
                  朋友圈动态、图文作品、二次创作转发等可一键分享到微信、微博、QQ空间等社交平台，精美打造个人的内容头条号。
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="about" className="outest-flex">
          <div className="fixed-width">
            <div className="contact-left">
              <div className="contact-title">
                <span className="china">关于我们</span>
                <span className="english">ABOUT US</span>
              </div>
              <div className="contact-content">
                渔鼓为原创用户提供强大的文本及图片编辑功能，发布后可随时修改编辑，解决微信、公众号和微博版面单调、不能重复编辑的痛点，为用户提供随心所欲的创作体验。
              </div>
              <div className="contact-content">
                可以转发者提供二次创作的机会，打破微信公众号、百度文章等不能直接转发至其他平台的桎梏，解决微博、美篇不能转发其他平台文章的痛点。
              </div>
            </div>
            <img src={coffee} className="coffee" alt=""/>
          </div>
        </section>
        <section id="why" className="outest-flex">
          <div className="fixed-width">
            <img src={phone2} className="phone2" alt=""/>
            <div className="why-right">
              <div className="why-title">
                <span className="china">推送最新创作</span>
                <span className="english">NEWEST</span>
              </div>
              <div className="why-content">
                渔鼓为原创用户提供强大的文本及图片编辑功能，发布后可随时修改编辑，解决微信、公众号和微博版面单调、不能重复编辑的痛点，为用户提供随心所欲的创作体验。
              </div>
              <div className="why-content">
                可以转发者提供二次创作的机会，打破微信公众号、百度文章等不能直接转发至其他平台的桎梏，解决微博、美篇不能转发其他平台文章的痛点。
              </div>
            </div>
          </div>
        </section>
        <section id="why2" className="outest-flex">
          <div className="fixed-width">
            <div className="contact-left">
              <div className="why2-title">
                <span className="china">原创文章</span>
                <span className="english">CREATION</span>
              </div>
              <div className="contact-content">
                渔鼓为原创用户提供强大的文本及图片编辑功能，发布后可随时修改编辑，解决微信、公众号和微博版面单调、不能重复编辑的痛点，为用户提供随心所欲的创作体验
              </div>
              <div className="why-content">
                可以转发者提供二次创作的机会，打破微信公众号、百度文章等不能直接转发至其他平台的桎梏，解决微博、美篇不能转发其他平台文章的痛点。
              </div>
            </div>
            <img src={phone3} className="phone3" alt=""/>
          </div>
        </section>
        <section id="why3" className="outest-flex">
          <img src={app} className="app-logo" alt=""/>
          <div className="why3-right">
            {/* <div className="why3-txt">BELIEVE ME</div>
            <div className="why3-txt">YOU DON'T YUGU</div>
            <div className="why3-txt">MISS THIS APP.</div> */}
            <button className="btn">敬请期待</button>
          </div>
        </section>
        <section id="contact" className="outest-flex">
          <div className="fixed-width">
            <div className="foot-left">
              Guangdong Wenchang Education Technology Co. LTD
            </div>
            <div className="foot-right">
              <div className="foot-right-title">
                联系我们
              </div>
              <div className="info-row">
                <img src={location} className="location-icon" alt=""/>
                <span className="value">广东省 广州市 天河区</span>
              </div>
              {/* <div className="info-row">
                <img src={phone} className="address-icon" alt=""/>
                <span className="value">020-87570363</span>
              </div>
              <div className="info-row">
                <img src={email} className="address-icon" alt=""/>
                <span className="value">gkezy@qq.com</span>
              </div> */}
            </div>
          </div>
        </section>
      </div>
    )
  }
}
 
export default APP
